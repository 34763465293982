
/* Footer */

.footer {

	width: 96%;
	max-width: 1400px;
	margin: 0 auto;
	padding-top: 60px;
	// height: _size(footer_height_large);

	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	margin-top: 1rem;

	color: _palette(black);

	z-index: -100;

	// @include breakpoint(medium) {
	// 	height: _size(footer_height_medium);
	// }

	// @include breakpoint(small) {
	// 	height: _size(footer_height_small);
	// }

	.footer_content {

		width: 100%;
		height: 100%;

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;

		text-align: center;

		.footer_info {

			font-weight: _font(medium);
    	font-size: 18px;
    	line-height: 1.8em !important;

		}

		.footer_social_container {
			margin: 60px 0;

			display: flex;
			flex-flow: row nowrap;
		}

		.footer_social_wrapper {

			width: 36px;
			height: 36px;
			border-radius: 50%;
			border: 3px solid _palette(black);
			color: _palette(black);
			margin: 0 1rem;

			display: flex;
			justify-content: center;
			align-items: center;

			transition: all 0.2s ease;

			&:hover {
				background: _palette(black);
				cursor: pointer;

				.footer_social {
					color: _palette(white) !important;
				}
			}

			.footer_social {
				color: _palette(black);
				font-size: 12pt;
				line-height: 36px;
				// margin-left: 1px;
				// margin-top: 2rem;
			}

		}

	}

}
