
/* Contents */ 

.contents {

  .content {

  	.contents_p {
  		font-size: 18px;
  		margin: 60px 15px;
  	}

  	.contents_title {
  		font-size: 22px;
  		margin: 0 15px;
  	}

  	.contents_list {

  		margin: 15px;
  		margin-left: 60px;

  		.contents_item {

  			list-style-type: circle;
  			list-style-position: outside;

  			.list_paragrah {
  				font-size: 18px;
  				margin: 0.5rem 1rem;
  			}
  		}

  	}

  	.contents_image {
  		width: 100%;
  		margin-top: 60px;
  	}

  	.contents_wrapper {
  		// width: 100%;
  		margin: 0 15px;

  		display: flex;
  		flex-flow: row wrap;
  		justify-content: flex-start;
  		align-items: center;

  		.contents_link {

  			box-sizing: border-box;
  			position: relative;
  			width: 50%;
  			overflow: hidden;

  			&:hover {

          cursor: pointer;

	        &::after {
		        background-color: rgba(255, 84, 150, 0.3);
		        z-index: 1000;
	        }

  			}

        &::after {
        	content: "";
        	position: absolute;
        	top: 0;
        	right: 0;
        	bottom: 0;
        	left: 0;
        	// width: 100px;
        	// height: 100px;
	        // background: lightskyblue;
	        // background-repeat: no-repeat;
	        // background-size: cover;
	        // background-position: center;
	        background-color: rgba(255, 84, 150, 0.0);
	        // background-blend-mode: luminosity;

	        transition: all 0.2s ease;

	        z-index: 1000;
        }

	  		.contents_image {
	  			display: block;
	  			width: 100%;
	  			transition: all 0.2s ease;
	  		}

  		}

  	}

  }

}

