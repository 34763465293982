
/* Filmmakers */ 

.filmmakers {

  .content {

    .paragraph {
      margin: 1rem;
      margin-top: 2rem;
      font-size: 1.2rem;
      text-align: center;
    }

  }

}

