
@-ms-viewport {
	width: device-width;
}

* {
	box-sizing: border-box !important;
}

html {
	
	height: 100%;
	min-height: 300px;

	.body {

	  // height: 100%;
	  margin: 0;

	  background-image: url('../../images/background_high.jpg');
	  background-size: cover;
    background-position: center 0px;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &.fixed {
    	overflow: hidden;
    }

	  .root {

	  	// height: 100%;
	  	overflow-x: hidden;
	  	// overflow-y: scroll;

	  	.main {

	  		position: relative;

				width: 80%;
				min-width: _measures(min_width);
				// min-height: calc(100% - #{_measures(main_margin_top_large)} - #{_measures(main_margin_bottom_large)});
				margin: 0 auto;
				margin-top: _measures(main_margin_top_xlarge);
				margin-bottom: _measures(main_margin_bottom_xlarge); 
				// margin: _measures(main_margin_top_large) auto _measures(main_margin_bottom_large) auto;

				background: _palette(white);

				@include breakpoint(large) {
					margin-top: _measures(main_margin_top_large);
					margin-bottom: _measures(main_margin_bottom_large);
				}

				@include breakpoint(medium) {
					width: 100%;
					min-width: auto;
					margin-top: _measures(main_margin_top_medium);
					margin-bottom: _measures(main_margin_bottom_medium);
				}

				@include breakpoint(small) {
					margin-top: _measures(main_margin_top_small);
					margin-bottom: _measures(main_margin_bottom_small);
				}

				.content {

					width: calc(100% - 120px);
					max-width: 1280px;
					// margin: 0 60px;
					margin: 0 auto;

					// min-height: calc(100% - #{_size(header_height_large)} - #{_measures(footer_height_large)});
					// width: calc(100% - #{_size(header_height_large)});
					// margin: 0 _size(header_height_large) / 2;
					// padding: _size(header_height_large) 0;

					display: flex;
					flex-flow: column nowrap;
					justify-content: flex-start;
					align-items: flex-start;

					z-index: 1000;

					@include breakpoint(medium) {
						// min-height: calc(100% - #{_size(header_height_medium)} - #{_measures(footer_height_medium)});
						// width: calc(100% - #{_size(header_height_medium)});
						// margin: 0 _size(header_height_medium) / 2;
						// padding: _size(header_height_medium) 0;

						width: calc(100% - 60px);
						margin: 0 30px;

					}

					// @include breakpoint(small) {
					// 	min-height: calc(100% - #{_size(header_height_small)} - #{_measures(footer_height_small)});
					// 	// width: calc(100% - #{_size(header_height_small)});
					// 	// margin: 0 _size(header_height_small) / 2;
					// 	// padding: _size(header_height_small) 0;
					// }

					.title {
						// border: 1px solid red;
						padding-bottom: 10px;
						border-bottom: 3px solid _palette(black);
						font-size: 30px;
						font-weight: _font(medium);
    				line-height: 41px;
    				text-transform: uppercase;
    				margin: 0 15px;

    				@include breakpoint(medium) {
    					margin: 0;
    				}

					}

  				.subtitle {
					font-family: 'Roboto', sans-serif;
				    color: #777777;
				    font-size: 15px;
				    font-weight: 300;
    				margin: 0 15px;
    				margin-top: 1rem;
    				// line-height: 26px;
    				letter-spacing: 0px;
  				}

				}

			}

	  }

	}

}

// ::-webkit-scrollbar {
//     width: 0px;
//     background: transparent;
// }

// ::-webkit-scrollbar-thumb {
//     background: #FFFFFF;
// }