
/* Planner */ 

.planner {

  .content {

    .services_title_wrapper {

      width: 100%;
      margin: 60px 0;

      .services_title {
        width: 100%;
        font-size: 22px !important;
        text-align: center;
        font-weight: _font(light) !important;

        &.bold {
          font-weight: _font(bold) !important;
        }
      }

    }

    .services_image {
      width: 100%;
    }

  }

}

