/* Typography */

@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow:200,300,400&display=swap');

@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir-black.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir-heavy.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir-medium.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/avenir-light.otf");
  font-weight: 200;
  font-style: normal;
}

	* {
		font-family: _font(family_accent);
		// -webkit-font-smoothing: antialiased;
	}

	body, input, textarea, select {
		font-family: _font(family_accent);
		font-weight: _font(regular);
		font-size: 16pt;
		line-height: 1.75em;
		color: _palette(fg);
		letter-spacing: 0.025em;

	  // -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;

		@include breakpoint(xxlarge) {
			font-size: 12pt;
		}

		@include breakpoint(xlarge) {
			font-size: 12pt;
		}

		@include breakpoint(large) {
			font-size: 11pt;
		}

		@include breakpoint(medium) {
			font-size: 11pt;
		}

		@include breakpoint(small) {
			font-size: 10pt;
			line-height: 1.5em;
		}

		@include breakpoint(xsmall) {
			font-size: 10pt;
			line-height: 1.5em;
		}
		
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: _font(family_accent);
		font-weight: _font(bold);
		color: inherit;

		a {
			color: _palette(accent_color) !important;
			text-decoration: none;
		}

	}

	h2 {
		font-size: 18pt;

		@include breakpoint(large) {
			font-size: 16pt;
		}

		@include breakpoint(small) {
			line-height: 1.2em;
		}

	}

	p, ul, ol, dl, table {
		font-family: _font(family_paragraphs);
		font-weight: _font(light);
		// font-family: 'Barlow', sans-serif !important;
    // font-weight: 300 !important;
    color: #272727 !important;
    letter-spacing: 1px !important;
    line-height: 1.2em !important;
	}

	strong, b {
		font-weight: _font(bold);
		color: inherit;
	}

	em, i {
		font-style: italic;
	}

	a {
		@include vendor('transition', 'color #{_duration(transition)} ease-in-out');
		color: _palette(primary_color);
		text-decoration: none;
	}

	button {
		font-family: _font(family_accent);
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	hr {
		border: 0;
		border-top: solid 1px _palette(border);
	}

	blockquote {
		border-left: solid 0.5em _palette(border);
		padding: 1em 0 1em 2em;
		font-style: italic;
	}
	
