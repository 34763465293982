
// measures.
	$measures: (

		min_width: 1000px,

		main_margin_top_xlarge: 30px,
		main_margin_bottom_xlarge: 72px,

		main_margin_top_large: 30px,
		main_margin_bottom_large: 72px,

		main_margin_top_medium: 0px,
		main_margin_bottom_medium: 0px,

		main_margin_top_small: 0px,
		main_margin_bottom_small: 0px,

	);

// Duration.
	$duration: (

		transition_hover:		0.15s,
		fade-in:			1.0s

	);

// Size.
	$size: (

		header_height_large:		100px,
		header_height_medium:		100px,
		header_height_small:		100px,

		footer_height_large:		224px,
		footer_height_medium:		224px,
		footer_height_small:		224px,

	);

// Font.
	$font: (

		family_accent:			('Barlow Condensed', sans-serif),
		family_paragraphs:	('Barlow', sans-serif),
		family_regular:			('Avenir', serif),

		thin:					100,
		extra_light:	200,
		light:				300,
		regular:			400,
		medium:				500,
		semi_bold:		600,
		bold:					700,
		extra_bold:		800,
		black:				900,

	);

// Palette.
	$palette: (

		white:				#ffffff,
		black:				#272727,

		background_color:	#000000,

		primary_color:		#00a1e4,
		primary_light:		#a9b0b8,
		primary_dark:			#494c4f,

		accent_color:		#ff5495,

		shadow_dark:		#05494c4f,

		font: (
			dark:		#303030,
			light:	#ffffff,
		)
	);
