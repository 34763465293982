.video {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(black, 0.85);

  z-index: 1000000;

  &.hidden {
  	background: none;
  	z-index: -1000;
  }

  .video_container {
  	position: relative;
  	padding: 1rem 0;

  	.video_close {
  		position: absolute;
  		top: -2rem;
  		right: 0;

  		.video_icon {
  			color: white;
  			font-size: 2rem;
  			line-height: 1rem;
  			cursor: pointer;

  			&:hover {
  				color: _palette(accent_color);
  			}
  		}
  	}
  }

}