
/* Services */ 

.services {

  .content {

  	position: initial;

  	.services_title_wrapper {

  		width: 100%;
  		margin: 60px 0;

	  	.services_title {
	  		width: 100%;
	  		font-size: 22px !important;
	  		text-align: center;
	  		font-weight: _font(light) !important;

	  		&.bold {
	  			font-weight: _font(bold) !important;
	  		}
	  	}

	  }

	  .services_image {
	  	width: 100%;
	  }

	  .services_p {
	  	font-size: 21px !important;
    	line-height: 30px !important;
  		margin: 0;
  		margin-top: 30px;
	  }

	  .services_offers {
	  	width: 100%;
	  	margin-top: 30px;

	  	.offers_title {
	  		color: _palette(accent_color);
	  		font-size: 23px;
    		line-height: 50px;
	  		letter-spacing: 1px;
	  		font-weight: _font(medium);
	  		margin: 15px 0;
	  	}

	  	.offers_wrapper {

	  		display: flex;
	  		flex-flow: row nowrap;
	  		justify-content: space-between;

	  		margin-bottom: 72px;

	  		@include breakpoint(small) {
	  			flex-flow: column;
	  			margin-bottom: 72px * 2;
	  		}

	  		.offers_list, .offers_info {
	  			width: 45%;
	  		}

	  		.offers_list {

	  			.offers_item {
	  				font-family: _font(family_accent);
					  font-size: 21px;
					  line-height: 36px;
					  font-weight: _font(medium);
					  letter-spacing: 1px;

					  text-decoration: none;

					  &::before {
					  	content: "+ ";
					  }

	  			}

	  		}

	  		.offers_info {

	  			.offers_p {

	  				font-family: _font(family_accent);
					  font-size: 21px;
					  line-height: 36px;
					  font-weight: _font(medium);
					  letter-spacing: 1px;
					  margin-bottom: 30px;

					  a {
					  	color: _palette(accent_color);
					  }

	  			}

	  		}

	  	}

	  }

  }

  .footer_img {
  	position: absolute;
  	bottom: calc(72px + #{_size(footer_height_large)});
  	right: 0;
  	width: 100%;
  	max-width: 960px;
  }

}

