
/* Marker */ 

.maker {

  .content {

  	.maker_wrapper {
      
  		width: calc(100% - 30px);
  		margin: 30px 15px;

  		display: flex;
  		flex-flow: row wrap;
  		justify-content: flex-start;
  		align-items: center;

  		.maker_container {

        position: relative;

  			box-sizing: border-box;
  			position: relative;
  			width: 33.3333%;
        background: black;
        overflow: hidden !important;

  			&:hover {
          cursor: pointer;
  			}

        @include breakpoint(small) {
          width: 50%;
        }

	  		.maker_image {
          position: relative;
	  			display: block;
	  			width: 100%;
	  			transition: all 0.2s ease;

          &:hover {
            cursor: pointer;
            transform: scale(1.05, 1.05);
          }

	  		}

  		}

  	}

  }

}

