
/* Header */

.header {

	width: 96%;
	max-width: 1400px;
	margin: 0 auto;
	height: _size(header_height_large);
	margin-bottom: 60px;

	z-index: 100000;

	@include breakpoint(medium) {
		height: _size(header_height_medium);
		padding: 0 _size(header_height_medium);
	}

	@include breakpoint(small) {
		height: _size(header_height_small);
		padding: 0 _size(header_height_small);
	}

	.header_content {

		height: 100%;
    max-width: 100%;
		margin: 0 auto;

		z-index: 100000;

		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		@include breakpoint(large) {
			// width: 96%;
		}

		@include breakpoint(medium) {
			justify-content: center;
		}

		.header_logo_wrapper {
			height: 90%;
			z-index: 100000;

			.header_logo {
				height: 100%;
			}
		}

		.nav_container {

			height: 100%;
			margin: 0 0.5rem;

			@include breakpoint(medium) {

				display: none;
				height: auto;

				position: fixed;
				top: _size(header_height_medium);
				right: 0;
				bottom: 0;
				left: 0;

				margin: 0;
				padding-bottom: _size(header_height_medium);

				background: _palette(white);

				z-index: 1000;

			}

			@include breakpoint(small) {
				top: 0;
				padding-top: _size(header_height_medium);
				z-index: 1000;
			}

			&.visible {
				display: block;
			}

			.nav_list {

				height: 100%;

				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;

				z-index: 10000;

				@include breakpoint(large) {
					justify-content: space-evenly;
				}

				@include breakpoint(medium) {
					flex-flow: column nowrap;
					justify-content: center;
				}

				.nav_item {

					z-index: 10000;

					.nav_link {

						position: relative;

						color: _palette(font, dark);
						font-family: _font(family_accent);
						font-size: 15px;
						font-weight: _font(medium);
						letter-spacing: 1px !important;
						text-transform: uppercase;
						// line-height: 26px;

						// -webkit-font-smoothing: antialiased !important;

						margin-right: 2rem;

						z-index: 1000;

						@include breakpoint(medium) {
							color: _palette(black);
							font-weight: _font(medium);
							font-size: 50px;
							line-height: 60px;
							letter-spacing: -1px !important;
							text-transform: none;
							margin-right: 0;
						}

						&:hover {
							color: _palette(accent_color);
						}

						// &:before, &:after {
						//   position: absolute;
						//   -webkit-transition: all 0.35s ease;
						//   transition: all 0.5s ease;
						// }

						// &:before {
						// 	bottom: -3px;
						// 	display: block;
						// 	height: 3px;
						// 	width: 0%;
						// 	content: "";
						// 	background-color: _palette(accent_color);
						// }

						// &:after {
						// 	left: 0;
						// 	top: 0;
						// 	padding: 0.5em 0;
						// 	position: absolute;
						// 	content: attr(data-hover);
						// 	color: #ffffff;
						// 	white-space: nowrap;
						// 	max-width: 0%;
						// 	overflow: hidden;
						// }

						// &:hover:before, & .current &:before {
						// 	opacity: 1;
						// 	width: 100%;
						// }

						// &:hover:after, & .current &:after {
						// 	width: 100%;
						// }

						&.current{
							// border-bottom: 3px solid _palette(accent_color); 
						}

					}

				}

			}

		}

		.menu_button_container {
			display: none;
			position: fixed;
			top: 0;
			right: 0;

			width: _size(header_height_large);
			height: _size(header_height_large);
				
			z-index: 10000;

			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;

			@include breakpoint(medium) {
				display: flex;
				width: _size(header_height_medium);
				height: _size(header_height_medium);
			}

			@include breakpoint(small) {
				width: _size(header_height_small);
				height: _size(header_height_small);
			}

			.menu_button {

				font-size: 16pt;

				transition: _duration(transition_hover) all ease;

				&:hover {
					// transform: scale(1.1, 1.1);
					color: _palette(accent_color);
				}

				@include breakpoint(medium) {
					box-shadow: none;
				}

			}

		}

	}

}