
/* Home */ 

.home {

  .content {

  	.banner {

  		width: 100%;

  		display: flex;
  		flex-flow: row nowrap;
  		justify-content: space-evenly;
			margin: 0 auto;
			// margin-bottom: 60px;

  		.slogan {

	  		display: flex;
	  		flex-flow: column nowrap;
	  		justify-content: center;
	  		align-items: flex-end;

  			.slogan_word {

  				font-family: _font(family_accent);
  				font-size: 80px;
    			line-height: 81px;
    			font-weight: _font(medium);
    			color: #272727;
    			letter-spacing: 0;

    			// @include breakpoint(medium) {
	  				// font-size: 54px;
	    		// 	line-height: 54px;
    			// }

    			@include breakpoint(small) {
	  				font-size: 42px;
	    			line-height: 42px;
    			}

  			}

  		}

  		.face_wrapper {
				max-width: 60%;
  			min-width: 200px;

  			.face {
  				width: 100%;
  			}
  		}

  	}

  	.us {
  		margin: 60px auto;
  		margin-top: 140px;
  		justify-content: space-between;

			@include breakpoint(small) {
				flex-flow: column;
			}

			.flor_wrapper {

				@include breakpoint(small) {
					max-width: 100%;
					margin-top: 30px;
				}

				.flor {
					width: 100%;
				}
			}

  	}

		.h3 {
			font-size: 30px;
    	line-height: 41px;
			font-weight: _font(medium);
		}

		.p {
			font-family: _font(family_paragraphs);
			font-size: 28px;
			letter-spacing: 1px;
    	line-height: 1.2em;
			font-weight: _font(extra_light);


			&.label {
				font-size: 1rem;
				margin-top: 1rem;
				font-style: italic;
			}

		}

  	.slider {

  		width: 100%;
  		margin: 60px auto;
  		overflow: hidden;

  		.slider_list {

  			width: 100%;

	  		display: flex;
	  		flex-flow: row nowrap;
	  		margin-top: 60px;

	  		&.animation {
	  			transition: all 0.5s ease;
	  			transform: translate(-20%, 0);
	  		}

  			.slider_wrapper {

  				min-width: 20% !important;

  				display: flex;
  				justify-content: center;
  				align-items: center;

  				@include breakpoint(small) {
  					width: 33.333% !important;
  				}

  				.slider_image {
  					width: 100%;
  					// border: 1px solid salmon;
  				}
  			}

  		}

  	}

		.contact {

			position: relative;
			width: 40%;
			margin: 60px auto;

			@include breakpoint(small) {
				width: 100%;
			}

			.contact_image {

				display: block;
				width: 100%;

				&.eye {
					position: relative;
					background: _palette(white);
					z-index: 100;

					&:hover {
						opacity: 0;
					}
				}

				&.eye_hover {

					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
					background: _palette(white);

					z-index: 0;

				}
			}
		}

		.map {

			position: relative;

			width: 100%;
			height: 458px;
			margin: 60px auto;

		}

  }

}

