
/* Card */

.card {

	// width: 100%;
	margin: 30px 0;

	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	@include breakpoint(small) {
		flex-direction: column-reverse;

	}

	&.odd {
		flex-direction: row-reverse;

		@include breakpoint(small) {
			flex-direction: column-reverse;
		}
	}

	.card_frame, .card_info {
		width: 50%;

		@include breakpoint(small) {
			width: 100%;
		}
	}

	.card_frame {

		padding: 20px;

		.card_image {

			width: 100%;
			// max-width: 200px;

		}

	}

	.card_info {

		padding: 30px;

		.card_title {
			font-size: 30px;
    		line-height: 40px;
			font-weight: _font(medium);
			margin-top: 30px;
		}

		.card_subtitle {
			font-weight: _font(medium);
		}

		.card_paragraph {
			font-size: 15px;
			font-family: 'Barlow', sans-serif !important;
		    font-weight: _font(light);
		    // color: #272727 !important;
		    letter-spacing: 1px;
		    line-height: 1.2em;
			margin: 30px 0;
		}

		.card_reel {
			font-size: 15px;
			font-family: 'Barlow', sans-serif !important;
		    font-weight: _font(bold);
		    // color: #272727 !important;
		    letter-spacing: 1px;
		    line-height: 1.2em;
			color: #303030;

			transition: all 0.1s ease;

			&:hover {
				color: red;
			}
		}

	}

}
